import React, { useEffect, useState, useRef, useCallback } from "react";
import Adpage from "../AdPage/Adpage";
// import QRCode from "react-qr-code";
import "./SloganCraft.css";
import axios from "axios";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Previous from "../../assets/previous.png";
import GenerateIcon from "../../assets/magic.png";
// import Microphone from "../../assets/mic.png";
import KPMGLogo from "../../assets/kpmg.svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/loading.json";
import QRCode from "../../assets/qrcodeV3.png";

// const API_URL = "https://slogan-backend.azurewebsites.net/";
const API_URL = "https://slogangenerator-app-demo.azurewebsites.net/";

const style = {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "30%",
  bgcolor: "white",
  border: "none",
  borderRadius: 2,
  p: 1,
};

function SloganCraft() {
  const [id, setId] = useState("");
  const [slogan, setSlogan] = useState("");
  const [subtext, setSubtext] = useState("");
  const [theme, setTheme] = useState("");
  // const [isListening, setIsListening] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showAdPage, setShowAdPage] = useState(false);
  const inactivityTimer = useRef(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const keyboardRef = useRef(null);
  const inputRef = useRef(null);
  // const [qrURL, setQrURL] = useState("");
  const [homePage, setHomePage] = useState(true);
  const [loading, setLoading] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUserActivity = useCallback(() => {
    clearTimeout(inactivityTimer.current);
    setShowAdPage(false);
    const timeoutDuration = 45000;
    inactivityTimer.current = setTimeout(() => {
      setShowKeyboard(false);
      setShowAdPage(true);
      setHomePage(true);
      setSlogan("");
      setSubtext("");
    }, timeoutDuration);
  }, [slogan]);

  useEffect(() => {
    const timeoutDuration = 45000;
    inactivityTimer.current = setTimeout(() => {
      setShowKeyboard(false);
      setShowAdPage(true);
      setHomePage(true);
      setSlogan("");
      setSubtext("");
    }, timeoutDuration);

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
      clearTimeout(inactivityTimer.current);
    };
  }, [slogan, handleUserActivity]);

  useEffect(() => {
    const preventContextMenu = (event) => {
      if (event.touches && event.touches.length === 2) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchstart", preventContextMenu, {
      passive: false,
    });
    document.addEventListener("contextmenu", preventContextMenu);

    return () => {
      document.removeEventListener("touchstart", preventContextMenu);
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);

  const handleTouchMove = (event) => {
    if (event.touches.length === 2) {
      const touch = event.touches[0];
      const simulatedEvent = new MouseEvent("mousemove", {
        bubbles: true,
        cancelable: true,
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      event.target.dispatchEvent(simulatedEvent);
    }
  };

  useEffect(() => {
    document.addEventListener("touchmove", handleTouchMove);

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  const handleThreeFingerTouch = (event) => {
    if (event.touches.length === 3) {
      const touch = event.touches[0];
      const simulatedEvent = new MouseEvent("contextmenu", {
        bubbles: true,
        cancelable: true,
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      event.preventDefault();
      event.target.dispatchEvent(simulatedEvent);
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleThreeFingerTouch, {
      passive: false,
    });

    return () => {
      document.removeEventListener("touchstart", handleThreeFingerTouch);
    };
  }, []);

  // const handleMicInput = () => {
  //   const recognition = new (window.SpeechRecognition ||
  //     window.webkitSpeechRecognition)();
  //   recognition.start();
  //   setIsListening(true);

  //   recognition.onresult = (event) => {
  //     const transcript = event.results[0][0].transcript;
  //     setTheme(transcript);
  //     setIsListening(false);
  //   };

  //   recognition.onend = () => {
  //     setIsListening(false);
  //   };
  // };

  const printSentence = (id, sentence, speed) => {
    let index = 0;
    let element = document.getElementById(id);

    if (!element) return;

    let timer = setInterval(function () {
      const char = sentence[index];
      if (char === "<") {
        index = sentence.indexOf(">", index);
      }
      element.innerHTML = sentence.slice(0, index);
      if (++index === sentence.length) {
        clearInterval(timer);
      }
    }, speed);
  };

  useEffect(() => {
    if (slogan.length > 1) {
      printSentence(
        "contentDiv",
        `You can <span>${slogan}</span> with AI.<span></span>`,
        80
      );
    }
    if (subtext.length > 1) {
      setTimeout(() => {
        printSentence("subtext", `${subtext}.`, 80);
      }, 5000);
      setTimeout(() => {
        printSentence("slogan-footer-link", "KPMG.ai ", 80);
      }, 9000);
    }
  }, [slogan, subtext]);

  const handleSubmit = async () => {
    if (!theme) return;

    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/generateSlogan`, { theme });
      const generatedId = response.data._id;
      const originalString = response.data.slogan;
      const modifiedString = originalString.replace(
        /^You can | with AI\.$/g,
        ""
      );
      // const generatedSlogan = (
      //   <span>
      //     You can <span className="highlight">{modifiedString}</span> with AI.
      //   </span>
      // );
      const generatedSlogan = modifiedString;
      const generatedSubtext = response.data.sentence;

      setId(generatedId);
      setSlogan(generatedSlogan);
      setSubtext(generatedSubtext);

      // setQrURL(
      //   `https://kpmg-you-can-with-ai.kpmgaicoe.com/feedback/?id=${generatedId}&theme=${theme}&slogan=${originalString}&subtext=${generatedSubtext}`
      // );

      // setQrURL(
      //   `https://kpmg-you-can-with-ai.kpmgaicoe.com/feedback/?id=${id}&theme=${theme}&slogan=${slogan}&subtext=${subtext}`
      // );

      setTheme("");
      setShowKeyboard(false);
      document.activeElement.blur();
      setOpen(false);
      setHomePage(false);
      setLoading(false);
    } catch (error) {
      console.error(
        "Error: There was a problem with the Axios operation",
        error
      );
    }
  };

  useEffect(() => {
    const checkBackendStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}/healthCheck`);
        if (response.data.status === "OK") {
          console.log("Backend is running and healthy.");
        } else {
          console.error("Backend status check failed.");
        }
      } catch (error) {
        console.log("There was a problem checking backend status:", error);
      }
    };
    checkBackendStatus();
  }, []);

  const handleRedirect = () => {
    setSlogan("");
    setHomePage(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyboardInput = (input) => {
    if (input === "{enter}") {
      handleSubmit();
    } else if (input === "{bksp}") {
      setTheme((prev) => prev.slice(0, -1));
    } else if (input === "{space}") {
      setTheme((prev) => prev + " ");
    } else if (input.length === 1) {
      setTheme((prev) => prev + input);
    }
  };

  const handleManualInput = (e) => {
    setTheme(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
      setShowKeyboard(false);
    }
  };

  const handleInputFocus = () => {
    setShowKeyboard(true);
  };

  const handleInputBlur = (e) => {
    if (keyboardRef.current && keyboardRef.current.contains(e.relatedTarget)) {
      return;
    }
    setShowKeyboard(false);
  };

  return (
    <div className="sloganCraft-container">
      {showAdPage && <Adpage onComplete={() => setShowAdPage(false)} />}
      {true && (
        <>
          <nav className="navbar">
            <img
              src={KPMGLogo}
              alt="KPMG Logo"
              className="navbar-logo"
              onClick={handleRedirect}
            />
            {!homePage && (
              <div className="generate-icon-button" onClick={handleClickOpen}>
                <img
                  src={GenerateIcon}
                  alt="Generate"
                  className="generate-icon"
                />
              </div>
            )}
            {!loading && (
              <Modal
                open={open}
                onClose={handleClose}
                componentsProps={{
                  backdrop: {
                    sx: {
                      background: `linear-gradient(90deg, rgba(62, 120, 243, 0.12) 0%, rgba(148, 77, 246, 0.12) 100%), radial-gradient(145.71% 592.18% at 145.71% -61.11%, rgba(233, 74, 154, 0.12) 12%, rgba(233, 74, 154, 0.05328) 43.05%, rgba(103, 45, 255, 0) 58.5%, rgba(103, 45, 225, 0.12) 100%), radial-gradient(79% 79% at 65.5% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, rgba(82, 182, 255, 0.12), rgba(82, 182, 255, 0.12));`,
                    },
                  },
                }}
              >
                <Box sx={style}>
                  <div className="modal-input-container">
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "black",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        value={theme}
                        onChange={(e) => setTheme(e.target.value)}
                        placeholder={`Enter your "You can with AI" idea here...`}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                      />

                      {/* <img
                        src={Microphone}
                        alt="Microphone"
                        className={`microphone-icon ${
                          isListening ? "listening" : ""
                        }`}
                        onClick={handleMicInput}
                      /> */}
                    </div>
                    <button className="submit-button" onClick={handleSubmit}>
                      Generate Idea
                      <img
                        src={GenerateIcon}
                        alt="Generate"
                        className="generate-icon"
                      />
                    </button>
                  </div>
                </Box>
              </Modal>
            )}

            <div className="nav-input-desktop">
              {!homePage && (
                <div className="nav-input-container">
                  <div className="input-wrapper">
                    <input
                      ref={inputRef}
                      type="text"
                      value={theme}
                      onChange={handleManualInput}
                      onKeyDown={handleKeyDown}
                      placeholder={`Enter your "You can with AI" idea here...`}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                    />
                    {/* <img
                      src={Microphone}
                      alt="Microphone"
                      className={`microphone-icon ${
                        isListening ? "listening" : ""
                      }`}
                      onClick={handleMicInput}
                    /> */}
                  </div>
                  <button className="submit-button" onClick={handleSubmit}>
                    Generate Idea
                    <img
                      src={GenerateIcon}
                      alt="Generate"
                      className="generate-icon"
                    />
                  </button>
                </div>
              )}
            </div>
          </nav>
          <main className={`body ${showKeyboard ? "" : "showKeyboard"}`}>
            {homePage &&
              (loading ? (
                <Lottie options={defaultOptions} height={400} width={400} />
              ) : (
                <div
                  className={`welcome-container ${
                    showKeyboard ? "" : "showKeyboard"
                  }`}
                >
                  <h1 className="hero-title">What can you do with AI?</h1>
                  <p className="hero-subtitle">
                    Do you want to break down silos with AI? Transform risk or
                    accelerate compliance? You can with AI.
                  </p>
                  <div className="input-container">
                    <div className="input-wrapper">
                      <input
                        ref={inputRef}
                        type="text"
                        value={theme}
                        onChange={handleManualInput}
                        onKeyDown={handleKeyDown}
                        placeholder={`Enter your "You can with AI" idea here...`}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                      />
                      {/* <img
                        src={Microphone}
                        alt="Microphone"
                        className={`microphone-icon ${
                          isListening ? "listening" : ""
                        }`}
                        onClick={handleMicInput}
                      /> */}
                    </div>
                    <button
                      className="submit-button"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Generate Idea
                      <img
                        src={GenerateIcon}
                        alt="Generate"
                        className="generate-icon"
                      />
                    </button>
                  </div>
                </div>
              ))}
            {slogan &&
              slogan != null &&
              (loading ? (
                <Lottie options={defaultOptions} height={400} width={400} />
              ) : (
                <div
                  className={`slogan-container ${
                    showKeyboard ? "" : "showKeyboard"
                  }`}
                >
                  <div id="contentDiv" className="slogan"></div>
                  <div id="subtext" className="subtext"></div>
                  <div
                    id="slogan-footer-link"
                    className="slogan-footer-link"
                  ></div>
                </div>
              ))}
          </main>
          {!loading && (
            <footer className={`footer ${!slogan ? "centered" : ""}`}>
              {slogan && (
                <div className="restart" onClick={handleRedirect}>
                  <img src={Previous} className="previous-icon" alt="Icon" />
                  <span>Back to home</span>
                </div>
              )}
              <p className="footer-text">KPMG. Make the Difference.</p>
              {slogan && (
                <div className="qr-code-container">
                  {/* <div
                    style={{
                      height: "100px",
                      margin: "0 auto",
                      width: "100px",
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{ height: "100px", width: "100px" }}
                      value={"https://kpmg-you-can-with-ai.kpmgaicoe.com/"}
                      viewBox={`0 0 256 256`}
                    />
                  </div> */}
                  <img src={QRCode} className="qr-code" alt="QR Code" />
                  <p className="qr-text">Scan to get your Quest points!</p>
                </div>
              )}
            </footer>
          )}
        </>
      )}
      {showKeyboard && (
        <div
          ref={keyboardRef}
          className="keyboard-container"
          onMouseDown={(e) => e.preventDefault()}
        >
          <Keyboard
            onKeyPress={handleKeyboardInput}
            layoutName={"default"}
            theme={"hg-theme-default custom-keyboard"}
            display={{
              "{bksp}": "backspace",
              "{enter}": "enter",
              "{space}": "space",
              "{tab}": "tab",
              "{shift}": "shift",
              "{lock}": "caps lock",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SloganCraft;
