import React, { useState, useEffect } from "react";
import "./FeedbackPage.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Checkmark from "../../assets/checkmark.png";

// const API_URL = "https://slogan-backend.azurewebsites.net/";
const API_URL = "https://slogangenerator-app-demo.azurewebsites.net/";

const FeedbackPage = () => {
  const [theme_id, setThemeID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [idea, setIdea] = useState("");
  const [slogan, setSlogan] = useState("");
  const [subtext, setSubtext] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const retrievedId = urlParams.get("id");
    const retrievedTheme = urlParams.get("theme");
    const retrievedSlogan = urlParams.get("slogan");
    const retrievedSubtext = urlParams.get("subtext");
    setThemeID(retrievedId || "");
    setIdea(retrievedTheme || "");
    setSlogan(retrievedSlogan || "");
    setSubtext(retrievedSubtext || "");
}, []);


  const validate = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = "Name is required";
    if (!email) tempErrors.email = "Email is required";
    if (!feedback) tempErrors.feedback = "Feedback is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await axios.post(`${API_URL}/feedback`, {
        theme_id,
        name,
        email,
        feedback,
      });
      console.log("Feedback submitted: ", response);
    } catch (error) {
      console.error("Error submitting feedback: ", error);
    }

    setName("");
    setEmail("");
    setFeedback("");
    setFeedbackSubmitted(true);
  };

  return (
    <div className="feedback-container">
      <div className="feedback">
        <h1>Feedback</h1>
        {!feedbackSubmitted && <div className="feedback-helper-text">
          Please provide your feedback on generated slogan and subtext
        </div>}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
            background: "white",
            borderRadius: 2,
            padding: 1.5,
            "& > :not(style)": { m: 1 },
          }}
        >
          {feedbackSubmitted ? (
            <>
              <div className="feedback-submitted-container">
                <img src={Checkmark} alt="Feedback sent checkmark" />
                <span className="feedback-sent-remark">
                  Thanks For your Feedback
                </span>
              </div>
            </>
          ) : (
            <>
              <ul className="choosen-idea">
                <li>
                  <label>Choosen idea: </label>
                  {idea}
                </li>
                <li>
                  <label>Generated slogan: </label>
                  {slogan}
                </li>
                <li>
                  <label>Generated Subtext: </label>
                  {subtext}
                </li>
              </ul>
              <TextField
                id="demo-helper-text-aligned-no-helper"
                label="Name"
                fullWidth
                color="secondary"
                required
                value={name}
                error={!!errors.name}
                helperText={errors.name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors({ ...errors, name: "" });
                }}
              />
              <TextField
                id="demo-helper-text-aligned-no-helper"
                label="Email"
                fullWidth
                color="secondary"
                required
                value={email}
                error={!!errors.email}
                helperText={errors.email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors({ ...errors, email: "" });
                }}
              />
              <TextField
                id="demo-helper-text-aligned-no-helper"
                label="Feedback"
                fullWidth
                color="secondary"
                required
                value={feedback}
                error={!!errors.feedback}
                helperText={errors.feedback || "Max 100 characters."}
                onChange={(e) => {
                  setFeedback(e.target.value);
                  setErrors({ ...errors, feedback: "" });
                }}
                multiline
                minRows={2}
                inputProps={{
                  maxLength: 300,
                }}
              />
              <button
                className="feedback-submit"
                onClick={handleFeedbackSubmit}
              >
                Submit
              </button>
            </>
          )}
        </Box>
      </div>
    </div>
  );
};

export default FeedbackPage;
