import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import SloganCraft from "./components/SloganCraft/SloganCraft";
import FeedbackPage from "./components/FeedbackPage/FeedbackPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<SloganCraft/>} />
        <Route path="/feedback" element={<FeedbackPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
