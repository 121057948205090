import React, { useEffect, useState } from "react";
import gif1 from "../../assets/desktopThree.gif";
import gif2 from "../../assets/desktopThree.gif";
import gif3 from "../../assets/desktopThree.gif";
import "./Adpage.css";

const Adpage = ({ onComplete }) => {
  const gifs = [gif1, gif2, gif3];
  const [currentGif, setCurrentGif] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentGif((prev) => (prev + 1) % gifs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [gifs.length]);

  useEffect(() => {
    if (userInteracted && currentGif === gifs.length - 1) {
      onComplete();
    }
  }, [currentGif, gifs.length, onComplete, userInteracted]);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("touchstart", handleUserInteraction);

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };
  }, []);

  return (
    <div className="ad-page">
      <img
        className="full-screen-gif"
        src={gifs[currentGif]}
        alt={`Ad ${currentGif + 1}`}
      />
    </div>
  );
};

export default Adpage;
